* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  /* background: #aa2626; */
}

canvas{
  /* position: fixed;
  top: 0;
  left: 0; */
  min-width: 100vw;
  min-height: 100vh;
  z-index: -1;
}

.section{
  position: relative;
  min-height: 100vh;
  display: flex;
  place-items: center;
}

.section h1{
  margin: 0 auto;
  max-width: 45ch;
  line-height: 1.5;
  font-weight: bold;
  font-size: 1.5rem;
  color:white;
  background-color:rgba(1,1,1,0.25);
  padding: 2em;
  border-radius: 50px;
}
